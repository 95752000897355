import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import scrollToRef from "../utils/scrollTarget";

const PartnersData = {
  ko: [
    {
      name: "SCG Grid",
      description: "partnerKoDescription_2",
      category: "partnerKoCategory_2",
      address: "partnerKoArea_2",
      logo: <Icons.BrandScgGrid />,
      link: "https://www.scggrid.com/",
    },
    {
      name: "SCG LAB",
      description: "partnerKoDescription_3",
      category: "partnerKoCategory_3",
      address: "partnerKoArea_3",
      logo: <Icons.BrandScgLab />,
      link: "http://www.scglab.com/",
    },
    {
      name: "partnerTitle1",
      description: "partnerKoDescription_6",
      category: "partnerKoCategory_6",
      address: "partnerKoArea_6",
      logo: <></>,
      link: "http://www.jubix.co.kr/",
    },
  ],
  global: [
    {
      name: "SCG Solutions Thailand",
      description: "partnerGlobalDescription_1",
      category: "partnerGlobalCategory_1",
      address: "partnerGlobalArea_1",
      logo: <Icons.LogoThailand />,
      link: "",
    },
    {
      name: "SCG Solutions Dalian Co.,Ltd.",
      description: "partnerGlobalDescription_2",
      category: "partnerGlobalCategory_2",
      address: "partnerGlobalArea_2",
      logo: <Icons.LogoDalian />,
      link: "",
    },
  ],
};

const PartnersStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;

const PartnersMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/partners.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const PartnersMain = ({ t }: any) => {
  return (
    <PartnersMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("partnerTitle")}
          </motion.h1>
        </motion.div>
      </div>
    </PartnersMainStyle>
  );
};
const PartnersKoStyle3 = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 100px auto;
    height: unset;
    width: 95%;
  }
  .partners-box {
    .inner-box {
      margin: 50px auto;
      h1 {
        font-size: 30px;
        color: #2d59a2;
        display: flex;
        border-bottom: 1px solid #2d59a2;
        margin-bottom: 10px;
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: #2d59a2;
        }
        aside {
          margin: auto 5px auto auto;
          padding: 3px 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid #2d59a2;
          transition: 0.3s;
          position: relative;
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: #2d59a2;
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      .partners-description {
        text-align: left;
        padding: 10px;
      }
      .partners-bottom {
        margin: 30px 0;
        .partners-bottom-box {
          display: flex;
          &:nth-of-type(1) {
            border-bottom: 2px solid #fff;
          }
          h2 {
            min-width: 150px;
            background: #2d59a2;
            color: #fff;
            min-height: 50px;
            display: flex;
            span {
              margin: auto;
            }
          }
          p {
            width: 100%;
            text-align: left;
            padding: 0 30px;
            display: flex;
            span {
              width: 100%;
              margin: auto 0;
            }
          }
        }
      }
      button {
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        position: relative;
        height: 30px;
        font-size: 16px;
        padding: 0 20px;
        background: #2d59a2;
        color: #fff;
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &:hover {
          background: #039;
        }
      }
    }
  }
  h1 {
    width: 100%;
    border-bottom: 2px solid #2d59a2;
    button {
      color: #2d59a2;
      width: 50%;
      height: 60px;
      font-size: 24px;
      font-weight: bold;
    }
    &.th button:last-child,
    &.kr button:first-child {
      background-color: #2d59a2;
      color: #fff;
      border: #2d59a2;
    }
  }
  button {
    cursor: pointer;
    transition: 0.3s;
    font-weight: 400;
    min-width: 120px;
    background-color: #fff;
    border: none;
    outline: none;
    a {
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    .scroll {
      margin: 100px auto;
      height: unset;
      width: 95%;
    }
    .partners-box {
      .inner-box {
        h1 {
          font-size: 20px;
          aside {
            padding: 1px 5px;
            font-size: 14px;
            font-weight: 400;
            border: 1px solid #2d59a2;
            transition: 0.3s;
            position: relative;
            a {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
            ::after {
              transition: inherit;
              content: "";
              position: absolute;
              width: 0%;
              height: 100%;
              background-color: #2d59a2;
              top: 0;
              left: 0;
              z-index: 0;
            }
            :hover {
              ::after {
                width: 100%;
              }
              p {
                color: #fff;
              }
            }
            p {
              position: relative;
              transition: inherit;
              margin: auto;
              z-index: 1;
            }
          }
        }
        .partners-description {
          padding: 0px;
        }
        .partners-bottom {
          margin: 30px 0;
          .partners-bottom-box {
            h2 {
              min-height: 36px;
              min-width: 70px;
            }
            p {
              min-height: 36px;
              padding: 0px;
              margin-left: 5px;
            }
          }
        }
      }
    }
    h1 {
      button {
        height: 36px;
        font-size: 16px;
      }
    }
  }
`;
const PartnersKo3 = ({ t }: any) => {
  const location = useLocation();
  const up1 = useRef(null);
  const [isKr, setIsKr] = useState(
    location.state?.type === true || location.state?.type === false
      ? location.state?.type
      : true
  );
  const [dataIndex, setDataIndex] = useState(Number);
  const [currentData, setCurrentData] = useState(PartnersData.ko[0]);
  useEffect(() => {
    isKr && setCurrentData(PartnersData.ko[dataIndex]);
    !isKr && setCurrentData(PartnersData.global[dataIndex - 5]);
  }, [isKr, dataIndex]);
  useEffect(() => {
    scrollToRef(up1, location);
  }, [location.state]);
  return (
    <PartnersKoStyle3>
      <div className="scroll" ref={up1}>
        <h1 className={`${isKr ? "kr" : "th"} no-select`}>
          <button onClick={() => !isKr && (setIsKr(true), setDataIndex(0))}>
            {t("partnerSubTitle1")}
          </button>
          <button onClick={() => isKr && (setIsKr(false), setDataIndex(5))}>
            {t("partnerSubTitle2")}
          </button>
        </h1>
        {isKr
          ? PartnersData.ko.map((res, idx) => {
              return (
                <div key={idx} className="partners-box">
                  <div className="inner-box">
                    <h1>
                      <p>{t(res.name)}</p>
                      <aside>
                        <p>{t("partnerLinkHomePage")}</p>
                        <Link target="_blank" to={res.link}></Link>
                      </aside>
                    </h1>
                    <div className="partners-description">
                      <p>{t(res.description)}</p>
                    </div>
                    <div className="partners-bottom">
                      <div className="partners-bottom-box">
                        <h2>
                          <span>{t("partnerBusiness")}</span>
                        </h2>
                        <p>
                          <span>{t(res.category)}</span>
                        </p>
                      </div>
                      <div className="partners-bottom-box">
                        <h2>
                          <span>{t("partnerLocation")}</span>
                        </h2>
                        <p>
                          <span>{t(res.address)}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : PartnersData.global.map((res, idx) => {
              return (
                <div key={idx} className="partners-box">
                  <div className="inner-box">
                    <h1>
                      <p>{t(res.name)}</p>
                      {idx === 0 && (
                        <aside>
                          <p>{t("partnerLinkHomePage")}</p>
                          <Link
                            target="_blank"
                            to={"http://www.scgs.co.th"}
                          ></Link>
                        </aside>
                      )}
                    </h1>
                    <div className="partners-description">
                      <p>{t(res.description)}</p>
                    </div>
                    <div className="partners-bottom">
                      <div className="partners-bottom-box">
                        <h2>
                          <span>{t("partnerBusiness")}</span>
                        </h2>
                        <p>
                          <span>{t(res.category)}</span>
                        </p>
                      </div>
                      <div className="partners-bottom-box">
                        <h2>
                          <span>{t("partnerLocation")}</span>
                        </h2>
                        <p>
                          <span style={{ lineHeight: "1.1em" }}>
                            {t(res.address)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </PartnersKoStyle3>
  );
};

const Partners = ({ t }: any) => {
  return (
    <PartnersStyle className="no-select">
      <PartnersMain t={t} />
      <PartnersKo3 t={t} />
    </PartnersStyle>
  );
};

export default Partners;
