import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Icons from "../assets/icons";
import Modal from "./Modal";
import { Link } from "react-router-dom";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}

const data = {
  head: {
    title: "",
    color: {
      main: "#5E2D00",
      hover: "#823f00",
      active: "#432000",
      background: "#fffefe",
    },
    type: "solar",
    description: ["solarDescription", "solarDescription1"],
  },
  item: [
    {
      title: "solar_1_title",
      itemImg: <div style={{ backgroundImage: "url('/icons/solar-1.png')" }} />,
      itemList: [
        {
          brand: <Icons.BrandJaSolar2 />,
          brandList: [
            {
              type: "solar2way",
              name: "JAM72D42-635/LB",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM72D42-630-635LB.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM72D42-630-635LB.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "JAM72D42-640/LB",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM72D42-640LB.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM72D42-640LB.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "JAM66D46-700LB",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-700LB(715W).pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-700LB(715W).pdf",
              ],
            },
            {
              type: "solar2way",
              name: "JAM66D46-705/LB",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-705-710LB.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-705-710LB.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "JAM66D46-710/LB",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-705-710LB.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/JAM66D46-705-710LB.pdf",
              ],
            },
          ],
        },
        {
          brand: <Icons.BrandJinkoSolar2 />,
          brandList: [
            {
              type: "solar2way",
              name: "JKM635N-78HL4-BDV-S",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/JinKo_JKM635N-78HL4-BDV-S.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/JinKo_JKM635N-78HL4-BDV-S.pdf",
              ],
            },
          ],
        },
        {
          brand: <Icons.BrandTrinaSolar2 />,
          brandList: [
            {
              type: "solar2way",
              name: "TSM-695NEG21C.20",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-695-700NEG21C.20.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-695-700NEG21C.20.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "TSM-700NEG21C.20",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-695-700NEG21C.20.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-695-700NEG21C.20.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "TSM-705NEG21C.20K",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-705NEG21C.20K.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/TSM-705NEG21C.20K.pdf",
              ],
            },
          ],
        },
        {
          brand: (
            <div
              style={{
                background: 'url("/icons/brand-hd.png")',
                width: "100%",
                height: "71px",
                backgroundSize: "200px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          ),
          brandList: [
            {
              type: "solar2way",
              name: "HiS-S590OJ-OT (1rate)",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S590OJ-OT.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S590OJ-OT.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "HiS-S500OH-OT (2rate)",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S500OH-OT_2%EB%93%B1%EA%B8%89.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S500OH-OT_2%EB%93%B1%EA%B8%89.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "HiS-T635NJ-ES (2rate)",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES_Datasheet_2%EB%93%B1%EA%B8%89.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES_Datasheet_2%EB%93%B1%EA%B8%89.pdf",
              ],
            },
          ],
        },
        {
          brand: (
            <div
              className="mobile-dp-none"
              style={{
                height: "71px",
              }}
            />
          ),
          brandList: [
            {
              type: "solar2way",
              name: "HiS-T640NJ-ES (2rate)",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES_Datasheet_2%EB%93%B1%EA%B8%89.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES_Datasheet_2%EB%93%B1%EA%B8%89.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "HiS-S500OH-ET",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S500OH-ET_%EB%AC%B4%EB%93%B1%EA%B8%89.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-S500OH-ET_%EB%AC%B4%EB%93%B1%EA%B8%89.pdf",
              ],
            },
            {
              type: "solar2way",
              name: "HiS-T635NJ-ES",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES(635-640W).pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HD_HiS-T635-640NJ-ES(635-640W).pdf",
              ],
            },
          ],
        },
        {
          brand: (
            <div
              className="mobile-dp-none"
              style={{
                height: "71px",
              }}
            />
          ),
          brandList: [
            {
              type: "solar2way",
              name: "HiS-T640NJ-ES",
              fileLink: [
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HiS-S600OJ-ET.pdf",
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/05+HD/HiS-S600OJ-ET.pdf",
              ],
            },
          ],
        },
      ],
    },
  ],
};
const data2 = {
  title: "solar_2_title",
  itemList: [
    {
      brand: (
        <div
          style={{
            background: 'url("/icons/brand-hd.png")',
            width: "100%",
            height: "35px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
          }}
        />
      ),
      name: "hd",
      fileLink:
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EC%84%A0%EA%B7%B8%EB%A1%9C%EC%9A%B0/%EC%84%A0%EA%B7%B8%EB%A1%9C%EC%9A%B0+%EB%8D%B0%EC%9D%B4%ED%84%B0%EC%8B%9C%ED%8A%B8.pdf",
      itemImg: (
        <div
          style={{
            backgroundImage: "url('/icons/solar-2-4.png')",
            backgroundSize: "90%",
          }}
        />
      ),
      brandList: [
        "HPC-036HL-OU(25A/35A/15A)",
        "HPC-060HL-OU(25A/35A/30A)",
        "HPC-125HL-OU(25A/30A/20A/35A)",
      ],
      brandLink: [
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/HD/HPC+036HL+OU(25A35A15A).pdf",
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/HD/HPC+060HL+OU(25A35A30A).pdf",
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/HD/HPC+125HL+OU(25A30A20A35A).pdf",
      ],
    },
    // {
    //     brand: <Icons.BrandHyosung />,
    //     name: "HYOSUNG",
    //     fileLink:
    //         "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%ED%9A%A8%EC%84%B1%EC%A4%91%EA%B3%B5%EC%97%85/%ED%9A%A8%EC%84%B1%EC%A4%91%EA%B3%B5%EC%97%85+%ED%83%9C%EC%96%91%EA%B4%91%EC%9D%B8%EB%B2%84%ED%84%B0+%EC%B9%B4%EB%8B%A4%EB%A1%9C%EA%B7%B8(%EC%A0%80)_%EC%B5%9C%EC%A2%85(220407).pdf",
    //     itemImg: (
    //         <div
    //             style={{ backgroundImage: "url('/icons/solar-2-2.png')" }}
    //         />
    //     ),
    //     brandList: ["HS-P36GLO", "HS-P50GLO", "HS-P100GLO", "HS-P175GLO"],
    // },
    {
      brand: <Icons.BrandGrowatt height={50} />,
      name: "growatt",
      fileLink:
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EA%B7%B8%EB%A1%9C%EC%99%80%ED%8A%B8/%EA%B7%B8%EB%A1%9C%EC%99%80%ED%8A%B8+%EB%8D%B0%EC%9D%B4%ED%84%B0%EC%8B%9C%ED%8A%B8.pdf",
      itemImg: (
        <div
          style={{
            backgroundImage: "url('/icons/solar-2-3.png')",
            backgroundSize: "85%",
          }}
        />
      ),
      brandList: ["MID-36KTL3-X2", "MID-50KTL3-X2", "MAX-125KTL3-X-LV"],
    },
    {
      brand: <Icons.BrandSolis />,
      name: "solis",
      fileLink:
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EC%86%94%EB%A6%AC%EC%8A%A4/S5-GC110K-K30.pdf",
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/solar-2-1.png')" }} />
      ),
      brandList: ["S5-GC60K-KR25", "S5-GC60K-KR30", "S5-GC110K(20A/25A/30A)"],
    },
    {
      brand: <Icons.BrandSungrow height={40} />,
      name: "sungrow",
      fileLink: "",
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/solar-2-1.png')" }} />
      ),
      brandList: ["SG33CX/50CX", "SG125CX-P2", "SG350HX"],
      brandLink: [
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EC%84%A0%EA%B7%B8%EB%A1%9C%EC%9A%B0/SG33CX(F25)_SG50CX(F25)(25A).pdf",
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EC%84%A0%EA%B7%B8%EB%A1%9C%EC%9A%B0/SG125CX-P2.pdf",
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%9D%B8%EB%B2%84%ED%84%B0/%EC%84%A0%EA%B7%B8%EB%A1%9C%EC%9A%B0/SG350HX_V1.4_EN.pdf",
      ],
    },
  ],
};
const data3 = {
  title: "solar_3_title",
  description: "solar_3_title_des",
  itemImg: <div style={{ backgroundImage: "url('/icons/dell-1-1.png')" }} />,
  itemList: [
    {
      info: <Icons.SolarBg1 />,
      info2: <Icons.SolarBg_en1 />,
      itemSubList: [
        "solar_3_con_1_1",
        "solar_3_con_1_2",
        "solar_3_con_1_3",
        "solar_3_con_1_4",
        "solar_3_con_1_5",
      ],
    },
    {
      info: <Icons.SolarBg2 />,
      info2: <Icons.SolarBg_en2 />,
      itemSubList: [
        "solar_3_con_2_1",
        "solar_3_con_2_2",
        "solar_3_con_2_3",
        "solar_3_con_2_4",
        "solar_3_con_2_5",
        "solar_3_con_2_6",
      ],
    },
    {
      info: <Icons.SolarBg3 />,
      info2: <Icons.SolarBg_en3 />,
      itemSubList: [
        "solar_3_con_3_1",
        "solar_3_con_3_2",
        "solar_3_con_3_3",
        "solar_3_con_3_4",
        "solar_3_con_3_5",
        "solar_3_con_3_6",
      ],
    },
    {
      info: <Icons.SolarBg4 />,
      info2: <Icons.SolarBg_en4 />,
      itemSubList: [
        "solar_3_con_4_1",
        "solar_3_con_4_2",
        "solar_3_con_4_3",
        "solar_3_con_4_4",
        "solar_3_con_4_5",
      ],
    },
    {
      info: <Icons.SolarBg5 />,
      info2: <Icons.SolarBg_en5 />,
      itemSubList: [
        "solar_3_con_5_1",
        "solar_3_con_5_2",
        "solar_3_con_5_3",
        "solar_3_con_5_4",
      ],
    },
  ],
};

const BusinessSolarStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessSolarMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-solar.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        margin: auto;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessSolarMain = ({ t }: any) => {
  return (
    <BusinessSolarMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{
              delay: 0.3,
              ease: "easeInOut",
              duration: 1,
            }}
          >
            {t("mainSolar")}
          </motion.h1>
        </motion.div>
      </div>
    </BusinessSolarMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.3s;
    .main-nav {
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: inherit;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .main-contents-header {
      padding: 30px 50px;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 30px;
      }
      button {
        display: flex;
        width: 150px;
        height: 40px;
        margin: 20px auto 0;
        border: 1px solid ${(props) => props.main};
        transition: 0.3s;
        position: relative;
        ::after {
          transition: inherit;
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          background-color: ${(props) => props.main};
          top: 0;
          left: 0;
          z-index: 0;
        }
        :hover {
          ::after {
            width: 100%;
          }
          p {
            color: #fff;
          }
        }
        p {
          transition: inherit;
          margin: auto;
          font-size: 14px;
          z-index: 1;
        }
      }
    }
    .main-contents-body {
      margin: 50px 0;
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
        aside {
          margin: auto 5px auto auto;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: ${(props) => props.main};
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      .module-area {
        margin: 50px auto;
        max-width: 1200px;
        display: flex;
        .contents-img {
          min-width: 200px;
          margin: auto 20px;
          div {
            aspect-ratio: 2/3;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .module-ul {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          position: relative;
          &.en-comment {
            ::before {
              bottom: 0;
              right: 0;
              font-size: 12px;
              position: absolute;
              color: #888;
              content: "SS: Single Side / DS: Double Side";
            }
          }
          .main-contents-item {
            width: 31%;
            margin-right: 10px;
            .list-area {
              text-align: left;
              font-size: 14px;
              li {
                display: flex;
                background-color: #f4f4f4;
                margin-bottom: 5px;
                transition: 0.3s;
                :hover {
                  transform: translate(-2px, -2px);
                  box-shadow: 2px 2px 5px 0 #00000022;
                }
                .module-type {
                  min-height: 50px;
                  height: inherit;
                  min-width: 40px;
                  display: flex;
                  color: #fff;
                  background-color: #ff9900;
                  font-weight: bold;
                  span {
                    margin: auto;
                  }
                }
                .module-name {
                  padding: 5px 10px;
                  align-self: center;
                }
                .module-icon {
                  display: flex;
                  position: relative;
                  margin-left: auto;
                  svg {
                    margin: auto;
                    min-width: 40px;
                    path {
                      fill: #ff9900;
                    }
                  }
                  a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0%;
                  }
                }
              }
            }
          }
        }
      }
      .inverter-area {
        margin: 50px auto;
        max-width: 1000px;
        display: flex;
        .contents-img {
          min-width: 200px;
          margin: auto 0;
          div {
            aspect-ratio: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .inverter-ul {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .main-contents-item {
            width: calc(50% - 25px);
            :nth-of-type(2n) {
              margin-left: 50px;
            }
            :nth-of-type(n + 3) {
              margin-top: 40px;
            }
            h2 {
              display: flex;
              margin-bottom: 10px;
              svg {
                height: 35px;
                width: auto;
              }
            }
            .list-frame {
              display: flex;
              background-color: #f4f4f4;
              .list-area {
                width: 100%;
                margin-left: 20px;
                text-align: left;
                font-size: 14px;
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                li {
                  display: flex;
                  width: 100%;
                  padding: 3px;
                  transition: 0.3s;
                  :hover {
                    transform: translate(-2px, -2px);
                    box-shadow: 2px 2px 5px 0 #00000022;
                  }
                  .inverter-name {
                    padding: 2px 5px;
                    width: 100%;
                    align-content: center;
                  }
                }
              }
              .inverter-icon {
                display: flex;
                position: relative;
                svg {
                  min-width: 40px;
                  margin: auto;
                  path {
                    fill: #ff9900;
                  }
                }
              }
            }
          }
        }
      }
      .business-area {
        margin: 50px auto;
        width: 100%;
        .business-ul {
          display: flex;
          width: 100%;
          .main-contents-item {
            width: 100%;
            background-color: #f4f4f4;
            & + .main-contents-item {
              margin-left: 10px;
            }
            .list-frame {
              display: flex;
              height: 100%;
              .list-area {
                width: 100%;
                text-align: left;
                font-size: 14px;
                transition: 0.3s;
                :hover {
                  transform: translate(-2px, -2px);
                  box-shadow: 2px 2px 5px 0 #00000022;
                  background-color: #ff7a0033;
                }
                .business-image {
                  background-color: #fff;
                  margin-bottom: 10px;
                  display: flex;
                  aspect-ratio: 1;
                  svg {
                    aspect-ratio: 1;
                    margin: auto;
                    max-width: 200px;
                    height: fit-content;
                    width: 100%;
                  }
                }
                li {
                  display: flex;
                  margin-bottom: 5px;
                  .business-type {
                    height: 50px;
                    min-width: 40px;
                    display: flex;
                    color: #fff;
                    background-color: #ff9900;
                    font-weight: bold;
                    span {
                      margin: auto;
                    }
                  }
                  .business-name {
                    display: flex;
                    ::before {
                      text-indent: 15px;
                      margin-right: 10px;
                      font-weight: bold;
                      content: "·";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  a {
    z-index: 1;
  }

  @media (max-width: 1024px) {
    .mobile-dp-none {
      display: none;
    }
    .main-contents {
      .main-contents-body {
        .module-area {
          display: unset;
          .contents-img {
            min-width: unset;
            margin: auto;
            max-width: 200px;
          }
          .module-ul {
            display: unset;
            &.en-comment {
              ::before {
                top: 0;
                content: "SS: Single Side / DS: Double Side";
              }
            }
            .main-contents-item {
              width: 100%;
              max-width: 600px;
              margin: auto;
              .list-area {
                li {
                  .module-type {
                    min-width: 50px;
                  }
                }
              }
            }
          }
        }
        .inverter-area {
          .inverter-ul {
            display: unset;
            .main-contents-item {
              width: 100%;
              :nth-of-type(n + 2) {
                margin-left: unset;
                margin-top: 50px;
              }
              .list-frame {
                .contents-img {
                  max-width: 200px;
                  min-height: unset;
                  margin: auto;
                }
                .list-area {
                  margin-left: unset;
                  padding-left: 20px;
                }
                .inverter-icon {
                  padding: 10px 0;
                  border: #ff9900 1px solid;
                  border-radius: 3px;
                  margin: 10px 20px;
                  svg {
                    min-width: 40px;
                    margin: auto;
                    path {
                      fill: #ff9900;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-contents {
      .main-contents-header {
        padding: 30px 0;
        h1 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      .main-contents-body {
        h1 {
          font-size: 20px;
        }
        .module-area {
          display: unset;
          .contents-img {
            min-width: unset;
            margin: auto;
            max-width: 200px;
          }
          .module-ul {
            display: unset;
            &.en-comment {
              ::before {
                top: 0;
                content: "SS: Single Side / DS: Double Side";
              }
            }
            .main-contents-item {
              .list-area {
                li {
                  .module-type {
                    min-width: 50px;
                  }
                }
              }
            }
          }
        }
        .inverter-area {
          .inverter-ul {
            display: unset;
            .main-contents-item {
              & + .main-contents-item {
                margin-left: unset;
                margin-top: 50px;
              }
              .list-frame {
                flex-direction: column;
                .contents-img {
                  max-width: 200px;
                  min-height: unset;
                  margin: auto;
                }
                .list-area {
                  margin-left: unset;
                  padding: 0 20px;
                }
                .inverter-icon {
                  padding: 10px 0;
                  border: #ff9900 1px solid;
                  border-radius: 3px;
                  margin: 10px 20px;
                  svg {
                    min-width: 40px;
                    margin: auto;
                    path {
                      fill: #ff9900;
                    }
                  }
                }
              }
            }
          }
        }
        .business-area {
          .business-ul {
            flex-direction: column;
            .main-contents-item {
              width: calc(100%);
              & + .main-contents-item {
                margin-left: unset;
                margin-top: 10px;
              }
              &:nth-of-type(2n + 1) {
                margin-right: 2px;
              }
              .list-frame {
                .list-area {
                  display: flex;
                  .business-image {
                    max-width: 150px;
                    margin-bottom: 0px;
                    svg {
                      max-width: unset;
                    }
                  }
                  li {
                    display: block;
                    .business-name {
                      ::before {
                        text-indent: 5px;
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = ({ t, i18n }: any) => {
  return (
    <BusinessItMainStyle
      main={data.head.color.main}
      active={data.head.color.active}
      hover={data.head.color.hover}
      background={data.head.color.background}
    >
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-header">
            <h1>{t(data.head.description[0])}</h1>
            <p>{t(data.head.description[1])}</p>

            {i18n.language === "ko" && (
              <button className="pointer">
                <p>{t("csButton_1")}</p>
                <Link
                  target="_blank"
                  to={"https://scgs.herokuapp.com/EP/counselling.html"}
                />
              </button>
            )}
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t(data.item[0].title)}</p>
            </h1>
            <div className="module-area">
              <div className="contents-img">{data.item[0].itemImg}</div>
              <ul
                className={`module-ul ${
                  i18n.language === "ko" ? "" : "en-comment"
                }`}
              >
                {data.item[0].itemList.map((res: any, idx) => {
                  return (
                    <li key={idx} className="main-contents-item">
                      <h2>{res.brand}</h2>
                      <ul className="list-area">
                        {res.brandList.map((res: any, idx: number) => {
                          return (
                            <li key={idx}>
                              <p className="module-type">
                                <span>{t(res.type)}</span>
                              </p>
                              <p className="module-name">{res.name}</p>
                              <p className="module-icon pointer">
                                <Icons.IconDownload />
                                <a
                                  href={`${
                                    i18n.language === "ko"
                                      ? res.fileLink[0]
                                      : res.fileLink[1]
                                  }`}
                                  download
                                  target="_blank"
                                />
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t(data2.title)}</p>
            </h1>
            <div className="inverter-area">
              <ul className="inverter-ul">
                {data2.itemList.map((res: any, idx) => {
                  return (
                    <div key={idx} className="main-contents-item">
                      <h2>{res.brand}</h2>
                      <div className="list-frame">
                        <div className="contents-img">
                          {data2.itemList[idx].itemImg}
                        </div>
                        <ul className="list-area">
                          {res.brandList.map(
                            (resList: any, idxList: number) => {
                              return (
                                <li key={idxList}>
                                  <p className="inverter-name">{resList}</p>

                                  {res.brandLink && (
                                    <p className="inverter-icon pointer">
                                      <Icons.IconDownload />
                                      <a
                                        href={res.brandLink[idxList]}
                                        download
                                        target="_blank"
                                      />
                                    </p>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                        {!res.brandLink && (
                          <p className="inverter-icon pointer">
                            <Icons.IconDownload />
                            <a href={res.fileLink} download target="_blank" />
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </article>
          <article className="main-contents-body">
            <h1>
              <p>{t(data3.title)}</p>
            </h1>
            <div className="business-area">
              <ul className="business-ul">
                {data3.itemList.map((res: any, idx) => {
                  return (
                    <div key={idx} className="main-contents-item">
                      <div className="list-frame">
                        <ul className="list-area">
                          {i18n.language === "ko" ? (
                            <div className="business-image">{res.info}</div>
                          ) : (
                            <div className="business-image">{res.info2}</div>
                          )}
                          <ul>
                            {res.itemSubList.map((res: any, idx2: number) => {
                              return (
                                <li key={idx2}>
                                  <p className="business-name">{t(res)}</p>
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessSolar = ({ t, i18n }: any) => {
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState("solar");
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <>
      <BusinessSolarStyle className="no-select">
        <BusinessSolarMain t={t} />
        <BusinessItMain t={t} i18n={i18n} setIsPopup={setIsPopup} />
      </BusinessSolarStyle>
      <AnimatePresence>
        {isPopup && (
          <Modal isPopup={isPopup} setIsPopup={setIsPopup} type={type}></Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BusinessSolar;
